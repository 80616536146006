@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url("./Images/waves.svg");
}

.bg-svg-hero {
  background-image: url('./Images/ffff-min.webp');
  background-size: cover;
}

.bg-wave-svg {
  background-image: url('./Images/blurry-gradient.webp');
  background-size: cover;
}

.bg-svg-form{
  background-image: url('./Images/slanted-gradient.svg');
  background-size: cover;
}

.bg-svg-services {
  background-image: url('./Images/layeredwave.svg');
}

.bg-svg-logo {
  background-image: url('./Images/TML.svg');
}

.texto-borde-b {
  -webkit-text-stroke: 2px #009dff;
  color: transparent; 
}

.texto-borde {
  -webkit-text-stroke: 2px black;
  color: transparent; 
}

.texto-borde-w {
  -webkit-text-stroke: 2px rgb(255, 255, 255);
  color: transparent; 
}


.glass-effect {
  backdrop-filter: blur(5px) saturate(180%);
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  background-color: #00000030;
  border: 1px solid #8080804d;
}

.no-hover-shadow:hover {
  box-shadow: none !important;
}